.backtest-form {
}

.config-textarea {
    width: 100%;
    background-color: var(--chart-light-background);
    border: none;
    outline: none;
    color: white;
}

