.header-container {

}

.header-container .left-panel {
    border-right: 1px dashed var(--light-border-color);
}

.title {
    color: white;
    font-size: 24px;
    font-weight: bold;
}