.autocomplete-input {
    outline: 0;
    background-color: var(--chart-background);
    border: 1px solid var(--regular-border-color);
    color: white;

    -webkit-box-shadow: inset 0 0 4px 0 #00000082;
    box-shadow: inset 0 0 4px 0 #00000082;
}

.autocomplete-input:focus {
    background-color: var(--button-hover-bg-color);
}

.pairSelectorContainer {
    display: flex;
    position: relative;
    width: 100%;
}

.no-suggestions {
    color: white;
}

.suggestions {
    z-index: 999;
    position: absolute;
    top: 40px;
}

.suggestions .item {
    color: white;
    border: 1px solid var(--regular-border-color);
    background-color: var(--chart-background);
}

.suggestion-active,
.suggestions .item:hover {
    cursor: pointer;
    background-color: var(--button-hover-bg-color);
}

.suggestions .item:not(:last-of-type) {
    border-bottom: 1px solid var(--regular-border-color);
}