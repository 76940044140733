:root {
  --primary-text-color: white;

  --main-background: #141516;
  --chart-background: #21242a;
  --chart-light-background: #262931;

  /* Borders */
  --regular-border-color: #121212;
  --light-border-color: #23272e;

  /* Buttons */
  --button-hover-bg-color: #2e323b;
  --button-selected-bg-color: #383c47;

  /* Modal */
  --modal-background-shadow: rgba(0, 0, 0, 0.65);
  --modal-background-color: rgba(20,21,22,0.5)

}

html, #root {
  background: var(--main-background);
  height: 100%;
}

body {
  margin: 0;
}


