.custom-modal-background {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: var(--modal-background-shadow);
    z-index: 999;
}

.custom-modal-container {
    background-color: var(--modal-background-color);
    color: var(--primary-text-color);
    backdrop-filter: blur(10px);
    border: 1px solid #191919;
}

.custom-modal .title {
    color: var(--primary-text-color);
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 0;
    color: var(--primary-text-color);
    margin-top: 5px;
}

.close-icon:hover {
    cursor: pointer;
}