button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
}

.custom-button {
    background-color: var(--chart-background);
    color: white;
    border: 1px solid var(--regular-border-color);
}

.custom-button:hover {
    background-color: var(--button-hover-bg-color);
}

.selected-button {
    background-color: var(--button-selected-bg-color);
}