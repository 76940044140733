.App {
    height: 100%;
}

.k-line-chart {
    height: 80vh;
    background: #191b20;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
}

.k-line-chart-container {
    height: 100%;
}

